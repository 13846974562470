import styles from '@styles/scss/common/_header.module.scss';
import genderUrl from 'lib/functions/genderFunction';
import { NavbarItem } from 'lib/types/item/navBarItem';
import { SidebarItem } from 'lib/types/item/sideBarItem';
import Link from 'next/link';
import { useState } from 'react';

import LeftNav from './leftnav';
import Navbar from './navbar';

export default function Header({
    sideBarItems,
    navBarItems,
    currentLang,
    gender
}: {
    sideBarItems: SidebarItem[];
    navBarItems: NavbarItem[];
    currentLang: string;
    gender: string;
}): JSX.Element {
    const [openLeftnav, setOpenLeftnav] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);

    const getLeftnav = function (val: boolean) {
        setOpenLeftnav(val);
    };

    const getSearch = function (val: boolean) {
        setOpenSearch(val);
    };
    return (
        <div className={styles.header}>
            <LeftNav
                sideBarItems={sideBarItems}
                currentLang={currentLang}
                getLeftnav={getLeftnav}
                leftnavStatus={openLeftnav}
                searchStatus={openSearch}
            />
            <Link href={genderUrl(gender)}>
                <div>
                    <svg
                        id="logoMain"
                        className={styles.logoContainer}
                        viewBox="0 0 250 80"
                        fill="none"
                        xmlns="https://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                            <path
                                d="M54.603 27.2565V23.4581C54.603 22.634 54.2832 21.8438 53.714 21.2611C53.1448 20.6784 52.3728 20.3511 51.5678 20.3511H6.13538C5.32953 20.3511 4.55654 20.6781 3.98582 21.2605C3.4151 21.8429 3.09322 22.6332 3.0907 23.4581V60.1084C3.09322 60.9333 3.4151 61.7236 3.98582 62.3059C4.55654 62.8883 5.32953 63.2154 6.13538 63.2154H51.5678C52.3728 63.2154 53.1448 62.888 53.714 62.3054C54.2832 61.7227 54.603 60.9324 54.603 60.1084V27.2565Z"
                                fill="#0D381C"
                            />
                            <path
                                d="M51.4737 17.6434H6.22205C4.65148 17.6459 3.14568 18.2844 2.03333 19.4194C0.920992 20.5544 0.292449 22.0937 0.284912 23.7014V59.738C0.297449 61.3423 0.928195 62.8767 2.04 64.0075C3.1518 65.1383 4.65476 65.7741 6.22205 65.7766H51.4737C53.041 65.7741 54.5439 65.1383 55.6557 64.0075C56.7676 62.8767 57.3983 61.3423 57.4108 59.738V23.7014C57.4083 22.0904 56.782 20.546 55.6691 19.4068C54.5562 18.2676 53.0475 17.6265 51.4737 17.6239V17.6434ZM56.3357 59.738C56.3357 61.058 55.8234 62.3239 54.9116 63.2573C53.9998 64.1906 52.7632 64.715 51.4737 64.715H6.22205C4.93422 64.7124 3.7 64.1869 2.79026 63.2538C1.88052 62.3208 1.36958 61.0563 1.36958 59.738V23.7014C1.36958 22.3832 1.88052 21.1187 2.79026 20.1856C3.7 19.2526 4.93422 18.7271 6.22205 18.7245H51.4737C52.7632 18.7245 53.9998 19.2488 54.9116 20.1822C55.8234 21.1156 56.3357 22.3815 56.3357 23.7014V59.738Z"
                                fill="#0D381C"
                            />
                            <path
                                d="M51.7785 26.4479C51.7785 27.0123 51.615 27.564 51.3086 28.0333C51.0023 28.5026 50.5669 28.8684 50.0574 29.0844C49.548 29.3004 48.9874 29.3569 48.4466 29.2468C47.9058 29.1367 47.409 28.8649 47.0191 28.4658C46.6292 28.0667 46.3637 27.5582 46.2561 27.0046C46.1485 26.4511 46.2038 25.8773 46.4148 25.3558C46.6258 24.8344 46.9831 24.3887 47.4416 24.0751C47.9001 23.7615 48.4391 23.5942 48.9905 23.5942C49.7299 23.5942 50.4391 23.8948 50.9619 24.43C51.4847 24.9652 51.7785 25.691 51.7785 26.4479Z"
                                fill="white"
                            />
                            <path
                                d="M48.9898 22.8636C49.6823 22.8636 50.3592 23.0738 50.935 23.4677C51.5108 23.8615 51.9595 24.4213 52.2245 25.0762C52.4895 25.7311 52.5589 26.4518 52.4238 27.1471C52.2887 27.8423 51.9552 28.481 51.4656 28.9822C50.9759 29.4835 50.352 29.8248 49.6729 29.9631C48.9937 30.1014 48.2897 30.0305 47.65 29.7592C47.0102 29.4879 46.4634 29.0285 46.0787 28.4391C45.694 27.8497 45.4886 27.1567 45.4886 26.4478C45.4911 25.498 45.8608 24.5879 46.5169 23.9163C47.1729 23.2446 48.062 22.8662 48.9898 22.8636ZM48.9898 21.7338C48.0791 21.7338 47.1888 22.0103 46.4315 22.5283C45.6743 23.0463 45.084 23.7825 44.7355 24.6439C44.387 25.5052 44.2958 26.453 44.4735 27.3675C44.6511 28.2819 45.0897 29.1218 45.7337 29.7811C46.3777 30.4404 47.1982 30.8893 48.0915 31.0712C48.9847 31.2531 49.9106 31.1598 50.752 30.803C51.5935 30.4462 52.3126 29.842 52.8186 29.0668C53.3246 28.2916 53.5947 27.3802 53.5947 26.4478C53.5846 25.2044 53.0951 24.0153 52.2326 23.1397C51.3701 22.264 50.2045 21.7727 48.9898 21.7728"
                                fill="white"
                            />
                            <path
                                d="M15.8321 23.6429C14.9473 20.4191 13.9292 16.8251 12.0263 14.9551C11.5124 14.4648 10.8654 14.1456 10.1707 14.0395C9.4759 13.9334 8.76604 14.0455 8.1348 14.361C4.67147 16.4842 5.51827 22.6202 6.66003 26.6427C7.83679 30.5452 9.37898 34.3223 11.2651 37.9212C6.82178 42.2262 4.25283 48.479 3.93884 57.0305C5.31847 55.8033 6.5744 53.9625 8.99112 53.7092C13.4915 53.2417 15.5467 56.9428 18.2013 58.5791C18.5819 57.5077 19.0005 56.4656 19.4287 55.4624C20.2945 55.6182 21.2745 55.6572 22.0928 55.852C21.7122 56.8941 21.3221 58.0726 20.9225 59.1732C20.8083 59.4946 20.4182 60.0011 20.8559 60.0985C21.2936 60.1959 21.284 59.7186 21.4458 59.3095C21.9025 58.1213 22.2831 57.0012 22.7398 56.0565C23.6329 56.1238 24.5201 56.2572 25.3944 56.4558C24.8425 58.0531 24.205 59.5725 23.7103 61.2283C32.654 63.556 40.7415 59.0855 40.6939 50.4172C40.6939 44.7098 37.516 41.2912 34.6711 38.2037C37.5255 33.9182 40.4941 29.5646 42.6444 24.461C43.5301 22.6674 44.0359 20.7026 44.1287 18.6951C44.1287 16.4161 42.7205 14.361 39.9803 14.5753C37.4684 14.7603 35.9176 16.981 34.7377 18.851C31.8358 23.302 29.9804 29.5646 28.2488 34.8435C24.9268 33.833 21.4282 33.5836 18.0015 34.113C17.4614 30.5859 16.7373 27.0908 15.8321 23.6429Z"
                                fill="#0D381C"
                            />
                            <path
                                d="M15.8321 23.6429C14.9473 20.4191 13.9292 16.8251 12.0263 14.9551C11.5124 14.4648 10.8654 14.1456 10.1707 14.0395C9.4759 13.9334 8.76604 14.0455 8.1348 14.361C4.67147 16.4842 5.51827 22.6202 6.66003 26.6427C7.83679 30.5452 9.37898 34.3223 11.2651 37.9212C6.82178 42.2262 4.25283 48.479 3.93884 57.0305C5.31847 55.8033 6.5744 53.9625 8.99112 53.7092C13.4915 53.2417 15.5467 56.9428 18.2013 58.5791C18.5819 57.5077 19.0005 56.4656 19.4287 55.4624C20.2945 55.6182 21.2745 55.6572 22.0928 55.852C21.7122 56.8941 21.3221 58.0726 20.9225 59.1732C20.8083 59.4946 20.4182 60.0011 20.8559 60.0985C21.2936 60.1959 21.284 59.7186 21.4458 59.3095C21.9025 58.1213 22.2831 57.0012 22.7398 56.0565C23.6329 56.1238 24.5201 56.2572 25.3944 56.4558C24.8425 58.0531 24.205 59.5725 23.7103 61.2283C32.654 63.556 40.7415 59.0855 40.6939 50.4172C40.6939 44.7098 37.516 41.2912 34.6711 38.2037C37.5255 33.9182 40.4941 29.5646 42.6444 24.461C43.5301 22.6674 44.0359 20.7026 44.1287 18.6951C44.1287 16.4161 42.7205 14.361 39.9803 14.5753C37.4684 14.7603 35.9176 16.981 34.7377 18.851C31.8358 23.302 29.9804 29.5646 28.2488 34.8435C24.9268 33.833 21.4282 33.5836 18.0015 34.113C17.4614 30.5859 16.7373 27.0908 15.8321 23.6429Z"
                                fill="#0D381C"
                            />
                            <path
                                d="M10.3512 48.6346C12.3969 49.9105 14.395 51.2254 16.4502 52.5305C14.2047 55.9004 8.34357 52.8129 10.3512 48.6346Z"
                                fill="#0D381C"
                            />
                            <path
                                d="M35.7092 49.5701H35.8139C37.5837 53.8458 31.2942 56.3878 29.4673 52.9497C31.5987 51.8784 33.5873 50.6706 35.6902 49.5701"
                                fill="#0D381C"
                            />
                            <path
                                d="M16.5263 23.9844C15.6414 20.7508 14.6233 17.1666 12.7204 15.2966C12.2076 14.8047 11.5611 14.4837 10.8663 14.3758C10.1715 14.268 9.46109 14.3785 8.82893 14.6927C5.3656 16.816 6.18386 22.952 7.3161 26.9744C8.49213 30.8772 10.0343 34.6543 11.9212 38.253C7.48736 42.5871 4.90889 48.8205 4.59491 57.3622C5.97453 56.135 7.23047 54.3039 9.64719 54.0507C14.1476 53.5832 16.2028 57.2843 18.8574 58.9205C19.238 57.8589 19.6566 56.8168 20.0943 55.8038C20.9506 55.9597 21.9306 55.9986 22.7489 56.2032C22.3683 57.2356 21.9782 58.4238 21.5786 59.5244C21.4644 59.8458 21.0743 60.3425 21.512 60.4984C21.9496 60.6542 21.9401 60.1088 22.1019 59.6997C22.5586 58.5212 22.9391 57.3914 23.3958 56.4467C24.2885 56.5187 25.1753 56.6521 26.0504 56.846C25.4986 58.453 24.8706 59.9724 24.3663 61.6282C33.3101 63.9559 41.3976 59.4757 41.35 50.8074C41.35 45.0999 38.1721 41.6911 35.3272 38.6036C38.1816 34.3084 41.1502 29.9645 43.3005 24.8609C44.1862 23.0633 44.695 21.096 44.7943 19.0853C44.7943 16.816 43.3766 14.7512 40.6459 14.9752C38.1245 15.17 36.5736 17.4003 35.3938 19.2217C32.5394 23.6727 30.6365 29.9353 28.9048 35.2142C25.5956 34.1904 22.1069 33.9244 18.6861 34.435C18.15 30.9255 17.4354 27.4469 16.5453 24.0136"
                                fill="white"
                            />
                            <path
                                d="M11.0175 48.9759C13.0536 50.2518 15.0516 51.5667 17.1067 52.8718C14.8613 56.2417 9.0005 53.164 11.0175 48.9759Z"
                                fill="#0D381C"
                            />
                            <path
                                d="M36.3651 49.9008H36.4888C38.2586 54.1864 31.9691 56.7187 30.1422 53.2903C32.2736 52.2189 34.2622 51.0112 36.3651 49.9008Z"
                                fill="#0D381C"
                            />
                            <path
                                d="M72.3299 32.5544C62.2449 32.5544 62.2449 51.2643 72.3299 51.2643C74.0574 51.2684 75.7538 50.7939 77.2392 49.891L77.9147 56.2412C76.1598 57.0599 74.2582 57.4977 72.3299 57.5269C55.0427 57.5269 55.0807 26.36 72.3299 26.36C74.079 26.3569 75.8027 26.788 77.3534 27.6164L76.7254 33.879C75.4246 32.9931 73.8921 32.5313 72.3299 32.5544Z"
                                fill="black"
                            />
                            <path
                                d="M88.6381 25.6688H89.1804L99.9034 57.4785H94.1946L93.1575 54.1671H84.7181L83.719 57.4785H77.9436L88.6381 25.6688ZM91.2451 48.4207L88.9235 40.4341L86.5354 48.4207H91.2451Z"
                                fill="black"
                            />
                            <path
                                d="M123.414 26.2824H125.012V57.4493H119.846V40.3173L113.328 48.557L106.849 40.2783V57.4493H101.682V26.2824H103.367L113.414 39.7621L123.414 26.2824Z"
                                fill="black"
                            />
                            <path
                                d="M141.654 57.4494L136.526 45.7618H134.061V57.4494H128.971V26.7597H139.019C148.105 26.7597 148.99 42.1678 141.511 44.7878L147.43 57.4494H141.654ZM134.042 39.7621H138.571C142.025 39.7621 142.025 32.9444 138.571 32.9444H134.08L134.042 39.7621Z"
                                fill="black"
                            />
                            <path
                                d="M157.466 25.6688H158.008L168.731 57.4493H163.022L161.985 54.1378H153.546L152.547 57.4493H146.771L157.466 25.6688ZM160.073 48.4207L157.751 40.4341L155.373 48.4207H160.073Z"
                                fill="black"
                            />
                            <path
                                d="M170.512 26.711H180.599C185.119 26.711 187.936 30.6068 187.832 36.0318C187.756 38.3499 187.08 40.7458 185.472 42.0802C186.324 42.8425 187.003 43.7871 187.46 44.8473C187.918 45.9076 188.142 47.0576 188.117 48.2161C188.117 53.3684 184.729 57.4493 180.599 57.4493H170.512V26.711ZM175.679 39.0316H180.209C181.665 39.0316 182.56 37.6194 182.56 35.9442C182.56 34.2689 181.665 32.8567 180.209 32.8567H175.679V39.0316ZM175.679 51.3134H180.209C180.576 51.2839 180.935 51.1802 181.263 51.0082C181.591 50.8362 181.882 50.5994 182.12 50.3114C182.358 50.0233 182.538 49.6899 182.65 49.3302C182.761 48.9706 182.801 48.5919 182.769 48.2161C182.803 47.8386 182.762 47.4579 182.648 47.097C182.535 46.736 182.351 46.4023 182.108 46.1157C181.866 45.8292 181.569 45.5958 181.236 45.4295C180.903 45.2633 180.541 45.1676 180.171 45.1481H175.679V51.3134Z"
                                fill="black"
                            />
                            <path
                                d="M190.902 26.711H200.977C205.506 26.711 208.323 30.6068 208.218 36.0318C208.142 38.3499 207.466 40.7458 205.858 42.0802C206.711 42.8425 207.39 43.7871 207.847 44.8473C208.304 45.9076 208.528 47.0576 208.503 48.2161C208.503 53.3684 205.116 57.4493 200.977 57.4493H190.902V26.711ZM196.03 39.0316H200.559C202.014 39.0316 202.909 37.6194 202.909 35.9442C202.909 34.2689 202.014 32.8567 200.559 32.8567H196.03V39.0316ZM196.03 51.3134H200.559C200.926 51.2839 201.284 51.1802 201.612 51.0082C201.94 50.8362 202.232 50.5994 202.47 50.3114C202.708 50.0233 202.888 49.6899 202.999 49.3302C203.11 48.9706 203.151 48.5919 203.118 48.2161C203.152 47.8378 203.111 47.4563 202.997 47.0948C202.882 46.7332 202.698 46.399 202.455 46.1124C202.211 45.8257 201.913 45.5925 201.579 45.4268C201.245 45.2611 200.882 45.1663 200.511 45.1481H195.982L196.03 51.3134Z"
                                fill="black"
                            />
                            <path
                                d="M211.195 57.4494V26.7597H216.286V57.4494H211.195Z"
                                fill="black"
                            />
                            <path
                                d="M224.448 57.449V32.8953H218.1V26.7983H235.887V32.8953H229.539V57.449H224.448Z"
                                fill="black"
                            />
                            <path
                                d="M249.99 56.8832C250.012 57.0352 249.997 57.1897 249.947 57.3363C249.896 57.4829 249.811 57.6183 249.697 57.7334C249.583 57.8485 249.442 57.9407 249.285 58.0039C249.127 58.0671 248.956 58.0998 248.783 58.0998C248.61 58.0998 248.44 58.0671 248.282 58.0039C248.124 57.9407 247.984 57.8485 247.87 57.7334C247.755 57.6183 247.67 57.4829 247.62 57.3363C247.569 57.1897 247.555 57.0352 247.577 56.8832C247.555 56.7312 247.569 56.5768 247.62 56.4302C247.67 56.2836 247.755 56.1482 247.87 56.0331C247.984 55.918 248.124 55.8257 248.282 55.7625C248.44 55.6994 248.61 55.6667 248.783 55.6667C248.956 55.6667 249.127 55.6994 249.285 55.7625C249.442 55.8257 249.583 55.918 249.697 56.0331C249.811 56.1482 249.896 56.2836 249.947 56.4302C249.997 56.5768 250.012 56.7312 249.99 56.8832Z"
                                fill="black"
                            />
                            <path
                                d="M247.116 53.7483C247.705 52.7563 247.987 51.6054 247.925 50.4465C247.878 49.7648 247.449 49.1804 246.907 49.2486C246.364 49.3167 246.069 49.794 245.87 50.4465C245.289 52.2581 244.48 53.8749 242.786 53.8749C242.373 53.8686 241.966 53.7788 241.587 53.6106C241.208 53.4425 240.865 53.1993 240.579 52.8952C240.292 52.591 240.067 52.2318 239.917 51.8384C239.766 51.4449 239.693 51.0248 239.702 50.6024C239.655 49.609 239.801 48.616 240.131 47.6805L242.082 47.9727C241.78 48.7226 241.634 49.5286 241.653 50.3394C241.642 50.4942 241.663 50.6497 241.716 50.7952C241.769 50.9407 241.852 51.0726 241.96 51.1819C242.068 51.2912 242.197 51.3753 242.34 51.4282C242.483 51.4812 242.635 51.5018 242.786 51.4887C243.347 51.4205 243.604 50.8556 243.804 50.1933C244.385 48.4012 245.289 46.8429 246.907 46.8429C248.525 46.8429 249.876 48.1675 249.972 50.3394C250.01 51.5988 249.759 52.8498 249.239 53.9918L247.116 53.7483Z"
                                fill="black"
                            />
                            <path
                                d="M249.894 44.9437H239.779V39.1486H241.797V42.6257H243.823V39.5771H245.84V42.6257H247.867V38.9538H249.894V44.9437Z"
                                fill="black"
                            />
                            <path
                                d="M244.498 34.3369L239.741 37.4731V34.6778L242.595 32.9246L239.741 31.2007V28.4638L244.498 31.6195L249.893 27.9768V30.7137L246.43 32.9246L249.893 35.1647V37.9795L244.498 34.3369Z"
                                fill="black"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect
                                    width="250"
                                    height="52.0073"
                                    fill="white"
                                    transform="translate(0 14)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </Link>
            <Navbar
                navBarItems={navBarItems}
                currentLang={currentLang}
                gender={gender}
                getSearch={getSearch}
                searchStatus={openSearch}
                leftnavStatus={openLeftnav}
            />
        </div>
    );
}
