export default function genderUrl(gender: string) {
    let url: string;
    if (gender === 'boys') {
        url = '/guys/';
        return url;
    } else if (gender === 'tranny') {
        url = `/trans/`;
        return url;
    } else {
        url = '/';
        return url;
    }
}
