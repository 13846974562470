import buttonStyle from '@styles/scss/buttons/_buttons.module.scss';
import styles from '@styles/scss/common/_navbar.module.scss';
import { NavbarItem } from 'lib/types/item/navBarItem';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { useEffect, useState } from 'react';

export default function Navbar({
    navBarItems,
    currentLang,
    gender,
    getSearch,
    searchStatus,
    leftnavStatus
}: {
    navBarItems: NavbarItem[];
    currentLang: string;
    gender: string;
    getSearch: any;
    searchStatus: any;
    leftnavStatus: any;
}): JSX.Element {
    const [value, setValue] = useState('');
    const [open, setOpen] = useState(searchStatus);
    const [svg, setSvg] = useState(true);
    const [width, setWidth] = useState(0);
    const router = useRouter();
    let url: string;

    if (currentLang === 'en') {
        url = `/search/${value.replace(/\s+/g, '-').toLowerCase()}/`;
    } else {
        url = `/${currentLang}/search/${value.replace(/\s+/g, '-').toLowerCase()}/`;
    }

    useEffect(() => {
        setWidth(window.innerWidth);
        const handleResizeWindow = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResizeWindow);

        if (leftnavStatus) {
            setOpen(false);
            setSvg(true);
            getSearch(false);
        }
        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, [leftnavStatus]);

    const handleKeyPress = () => {
        if (value.length < 1) {
            router.push(`/search/`);
            setOpen(false);
            setSvg(true);
        } else {
            router.push(url);
            setOpen(false);
            setSvg(true);
        }
    };

    return (
        <div className={styles.navContainer}>
            <div className={styles.pageNav}>
                <section className={styles.pageToolbar}>
                    {width >= 1280 || open ? (
                        <div className={styles.searchDesktop}>
                            <input
                                placeholder={navBarItems[6].label}
                                value={value}
                                name="searchText"
                                autoComplete="off"
                                onChange={(e) => setValue(e.target.value)}
                                onKeyPress={(e) =>
                                    e.key === 'Enter' ? (handleKeyPress(), setValue('')) : null
                                }
                            />
                            <button
                                type="submit"
                                onClick={() => (setValue(''), setOpen(false), setSvg(true))}
                                data-event-category="User Action"
                                data-event-action="Header - Search Bar Use"
                                data-event-label="Header - Search Bar"
                                aria-label="search">
                                <Link href={value.length < 1 ? `/search/` : url}>
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="https://www.w3.org/2000/svg">
                                        <path
                                            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                                            fill="#222222"
                                        />
                                    </svg>
                                </Link>
                            </button>
                        </div>
                    ) : null}
                    <button
                        className={styles.mobileSearchButton}
                        aria-label="search"
                        onClick={() => {
                            setOpen(!open);
                            setSvg(!svg);
                            getSearch(!open);
                        }}>
                        {svg ? (
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="https://www.w3.org/2000/svg">
                                <path
                                    d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                                    fill="#222222"
                                />
                            </svg>
                        ) : (
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 20"
                                fill="none"
                                xmlns="https://www.w3.org/2000/svg">
                                <path
                                    d="M17.02 19.9H15.2C15.0133 19.9 14.8667 19.8467 14.76 19.74C14.6533 19.62 14.5667 19.5067 14.5 19.4L8.46 10.58C8.40667 10.82 8.30667 11.0533 8.16 11.28L2.4 19.4C2.30667 19.52 2.20667 19.6333 2.1 19.74C2.00667 19.8467 1.88 19.9 1.72 19.9H0L7.24 9.72L0.28 0H2.1C2.28667 0 2.42667 0.0400003 2.52 0.120001C2.61333 0.200001 2.69333 0.293334 2.76 0.400001L8.6 8.82C8.62667 8.71333 8.66667 8.6 8.72 8.48C8.78667 8.36 8.85333 8.24 8.92 8.12L14.38 0.420001C14.46 0.300001 14.5467 0.200001 14.64 0.120001C14.7333 0.0400003 14.8533 0 15 0H16.74L9.8 9.6L17.02 19.9Z"
                                    fill="#0D381C"
                                />
                            </svg>
                        )}
                    </button>
                    {open ? <div className={styles.searchMobileContainer}></div> : null}
                </section>
                {width >= 1280 ? (
                    <a
                        key={navBarItems[7].label}
                        className={styles.navItem}
                        href={`https://rabbits.webcam/?id=2333&product_id=36&tag=login&passthru1=${gender}&passthru2=${currentLang}&exid={exid}&tfid={tfid}`}
                        data-event-category="Outbound Click"
                        data-event-action="Login Click"
                        data-event-label="Header-CTA">
                        {navBarItems[7].label}
                    </a>
                ) : null}
                <a
                    className={buttonStyle.primaryButton}
                    href={`https://rabbits.webcam/?id=2333&product_id=36&tag=signup&passthru1=${gender}&passthru2=${currentLang}&exid={exid}&tfid={tfid}`}
                    data-event-category="Outbound Click"
                    data-event-action="Join Click"
                    data-event-label="Header-CTA">
                    <span>
                        {width >= 1280
                            ? navBarItems[8].label.toUpperCase()
                            : navBarItems[9].label.toUpperCase()}
                    </span>
                </a>
            </div>
        </div>
    );
}
