export default function home(route: string, language: string) {
    let home: string;

    if (route.includes('guy') && language !== 'en') {
        home = `/${language}/guys/`;
        return home;
    } else if (route.includes('guy')) {
        home = '/guys/';
        return home;
    } else if (language === 'en') {
        home = '/';
        return home;
    } else {
        home = `/${language}/`;
        return home;
    }
}
