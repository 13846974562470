import { useRouter } from 'next/dist/client/router';
import styles from 'styles/scss/common/_language-switcher.module.scss';
export default function LanguageSwitcher({ currentLang }: { currentLang: string }): JSX.Element {
    const router = useRouter();
    let index: number;

    function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
        if (router.asPath.split('/')[1] === 'search' && router.asPath.length === 8) {
            router.push(router.asPath.split('/')[1], router.asPath.split('/')[2], {
                locale: e.target.value
            });
        } else if (
            router.asPath.split('/')[1] === 'live' ||
            router.asPath.split('/')[1] === 'search'
        ) {
            router.push(router.asPath.split('/')[1], router.asPath.split('/')[2], {
                locale: e.target.value
            });
        } else if (router.asPath.split('/').length >= 3) {
            router.push(router.asPath, undefined, {
                locale: e.target.value
            });
        } else if (router.asPath.split('/')[1].length === 2) {
            router.push(router.asPath.split('/')[0] + '/' + e.target.value);
        } else {
            router.push(router.asPath.split('/')[1], router.asPath.split('/')[1], {
                locale: e.target.value
            });
        }
    }

    let selectedLanguage: string;

    if (currentLang === 'en') {
        selectedLanguage = 'English';
        index = 0;
    } else if (currentLang === 'es') {
        selectedLanguage = 'Español';
        index = 1;
    } else if (currentLang === 'de') {
        selectedLanguage = 'Deutsch';
        index = 2;
    } else if (currentLang === 'cs') {
        selectedLanguage = 'Čeština';
        index = 3;
    } else if (currentLang === 'da') {
        selectedLanguage = 'Dansk';
        index = 4;
    } else if (currentLang === 'fi') {
        selectedLanguage = 'Suomalainen';
        index = 5;
    } else if (currentLang === 'fr') {
        selectedLanguage = 'Français';
        index = 6;
    } else if (currentLang === 'hu') {
        selectedLanguage = 'Magayer';
        index = 7;
    } else if (currentLang === 'it') {
        selectedLanguage = 'Italiano';
        index = 8;
    } else if (currentLang === 'ja') {
        selectedLanguage = '日本語';
        index = 9;
    } else if (currentLang === 'nl') {
        selectedLanguage = 'Nederlands';
        index = 10;
    } else if (currentLang === 'no') {
        selectedLanguage = 'Norsk';
        index = 11;
    } else if (currentLang === 'pl') {
        selectedLanguage = 'Polski';
        index = 12;
    } else if (currentLang === 'pt') {
        selectedLanguage = 'Português';
        index = 13;
    } else if (currentLang === 'ro') {
        selectedLanguage = 'Româna';
        index = 14;
    } else if (currentLang === 'ru') {
        selectedLanguage = 'русский';
        index = 15;
    } else if (currentLang === 'sk') {
        selectedLanguage = 'Slovenčina';
        index = 16;
    } else if (currentLang === 'sv') {
        selectedLanguage = 'Svenska';
        index = 17;
    } else if (currentLang === 'zh') {
        selectedLanguage = '中文';
        index = 18;
    } else {
        selectedLanguage = 'Language';
        index = -1;
    }

    const languages = [
        { value: 'en', language: 'English' },
        { value: 'es', language: 'Español' },
        { value: 'de', language: 'Deutsch' },
        { value: 'cs', language: 'Čeština' },
        { value: 'da', language: 'Dansk' },
        { value: 'fi', language: 'Suomalainen' },
        { value: 'fr', language: 'Français' },
        { value: 'hu', language: 'Magayer' },
        { value: 'it', language: 'Italiano' },
        { value: 'ja', language: '日本語' },
        { value: 'nl', language: 'Nederlands' },
        { value: 'no', language: 'Norsk' },
        { value: 'pl', language: 'Polski' },
        { value: 'pt', language: 'Português' },
        { value: 'ro', language: 'Româna' },
        { value: 'ru', language: 'русский' },
        { value: 'sk', language: 'Slovenčina' },
        { value: 'sv', language: 'Svenska' },
        { value: 'zh', language: '中文' }
    ];
    languages.splice(index, 1);

    return (
        <div className={styles.languageContainer}>
            <select onInput={handleChange}>
                <option value="en">{selectedLanguage}</option>
                {languages.map((lg) => (
                    <option key={lg.value} value={lg.value}>
                        {lg.language}
                    </option>
                ))}
            </select>
        </div>
    );
}
