export default function homeStyle(route: string, styles: any) {
    let homeActive = '';

    if (route.length === 4) {
        homeActive = styles.categoryActive;
        return homeActive;
    } else if (route === '/' || route.split('/')[1] === 'guys') {
        homeActive = styles.categoryActive;
        return homeActive;
    } else if (route.length === 3) {
        homeActive = styles.categoryActive;
        return homeActive;
    }
}
